<script setup lang="ts">
import { numeric } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

interface Props {
  loading?: boolean;
  allowUndefinedOption?: boolean;
  placeholder?: string;
  label?: string;
  errorMessages?: string[];
  customClasses?: string;
  hideLabel?: boolean;
  hideErrorMessage?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  allowUndefinedOption: false,
  placeholder: undefined,
  label: undefined,
  errorMessages: () => [],
  customClasses: undefined,
  hideLabel: false,
  hideErrorMessage: false,
});

const value = defineModel<number | null | undefined>({ default: undefined });
const { t } = useI18n();
const { currentClassYearOptions, classYearNotUndefinedRule } = useClassYears();

const validation = useVuelidate(
  {
    classYear: {
      numeric,
      ...(props.allowUndefinedOption === false ? { notUndefinedRule: classYearNotUndefinedRule } : undefined),
    },
  },
  { classYear: value },
);

const _errorMessages = computed(() => {
  const errors = validation.value.$errors.map((e) => unref(e.$message));
  return [...props.errorMessages, ...errors];
});

const combinedOptions = computed(() => [...currentClassYearOptions.value, { value: null, label: t('general.graduated') }]);

const onBlur = () => {
  validation.value.$touch();
};
</script>

<template>
  <UISelectCustom
    v-model="value"
    :errorMessages="_errorMessages"
    :label="label || $t('general.formFields.grade.label')"
    :options="combinedOptions"
    :placeholder="placeholder || $t('general.formFields.grade.placeholder')"
    :disabled="loading"
    :autoClear="false"
    :customClasses="customClasses"
    nullable
    :clearable="allowUndefinedOption"
    :hideLabel="hideLabel"
    :hideErrorMessage="hideErrorMessage"
    data-testid="core-class-year-select"
    @blur="onBlur"
  />
</template>
